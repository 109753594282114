// @flow

import * as slicePaddingFactor from '../config/slicePaddingFactor';
import type { PaddingFactorInput } from '../../../components/BodySection';

const paddingFactorDict = ({
  default: undefined,
  '0': 0,
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  '6': 6,
  '7': 7,
  '8': 8,
  '9': 9,
  '10': 10,
  '11': 11,
  '12': 12,
}: $ObjMap<typeof slicePaddingFactor, <V>() => void | number>);

const getPaddingFactor = (value: mixed): void | number =>
  typeof value === 'string' ? paddingFactorDict[value] : undefined;

const sanitizePaddingFactor = (padding: {|
  top: mixed,
  right: mixed,
  bottom: mixed,
  left: mixed,
|}): void | PaddingFactorInput => {
  const paddingTopFactor = getPaddingFactor(padding.top);
  const paddingRightFactor = getPaddingFactor(padding.right);
  const paddingBottomFactor = getPaddingFactor(padding.bottom);
  const paddingLeftFactor = getPaddingFactor(padding.left);

  return typeof paddingTopFactor === 'number' ||
    typeof paddingBottomFactor === 'number'
    ? [
        paddingTopFactor,
        paddingRightFactor,
        paddingBottomFactor,
        paddingLeftFactor,
      ]
    : undefined;
};

export default sanitizePaddingFactor;
