// @flow
import type { Props, BackgroundColor } from '../types';

import sanitizeBackgroundColor from './sanitizeBackgroundColor';

const defaultBackgroundColor = <E: {}>({
  data,
}: Props<E>): void | BackgroundColor =>
  sanitizeBackgroundColor(data.primary && data.primary.slice_background_color);

export default defaultBackgroundColor;
