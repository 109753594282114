// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';

import RichText from '~plugins/prismic/components/RichText';
import NodeImage from '~plugins/prismic/components/NodeImage';
import FieldLink from '~plugins/prismic/components/FieldLink';
import type {
  PrismicStructuredTextType,
  PrismicLinkType,
  PrismicImageType,
} from '~schema';

export type ClassKey =
  | 'root'
  | 'backgroundImage'
  | 'content'
  | 'title'
  | 'hiddenContent'
  | 'hiddenContentContainer'
  | 'description'
  | 'link';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  item: {
    item_title?: ?PrismicStructuredTextType,
    item_description?: ?PrismicStructuredTextType,
    item_link_label?: ?PrismicStructuredTextType,
    item_link?: ?PrismicLinkType,
    item_image?: ?PrismicImageType,
  },
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    minHeight: 350,
    padding: theme.spacing(3),
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.complex,
    }),
    '&:hover $hiddenContent': {
      opacity: 1,
      height: 'auto',
      // It will never get this height, just for the animation to work
      maxHeight: '400px',
      flex: 1000,
      transform: 'translateY(0%)',
      transition: theme.transitions.create(['all'], {
        duration: theme.transitions.duration.complex,
      }),
    },
    '&:hover $backgroundImage:after': {
      background: 'rgba(0,0,0,.45)',
    },
    '&:hover $title': {
      flex: '0 0 auto',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 450,
    }
  },
  backgroundImage: {
    position: 'absolute !important',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
    pointerEvents: 'none',
    '&:after': {
      content: JSON.stringify(''),
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,.15)',
      transition: theme.transitions.create(['background']),
    },
  },
  content: {
    zIndex: 1,
    color: theme.palette.common.white,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.complex,
    }),
  },
  title: {
    zIndex: 1,
    flex: '1 0 auto',
    textShadow: '4px 4px 15px rgba(0,0,0,.3)',
  },
  hiddenContent: {
    opacity: 0,
    height: 0,
    maxHeight: 0,
    flex: 0,
    transform: 'translateY(100%)',
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.complex,
    }),
  },
  hiddenContentContainer: {
    [theme.breakpoints.up('md')]: {
      padding: 0,
      margin: 0,
    },
  },
  description: {
    paddingTop: theme.spacing(1),
    textShadow: '4px 4px 15px rgba(0,0,0,.3)',
  },
  link: {
    marginTop: theme.spacing(3),
  },
});

const ContentGridItemSlice = ({
  item,
  className,
  classes,
  ...props
}: Props): React.Node => {
  return (
    <Paper className={classnames(className, classes.root)} {...props}>
      <NodeImage data={item.item_image} className={classes.backgroundImage} />
      <div className={classes.content}>
        <RichText {...item?.item_title} className={classes.title} />
        <div className={classes.hiddenContent}>
          <Container maxWidth="sm" className={classes.hiddenContentContainer}>
            <RichText
              {...item?.item_description}
              className={classes.description}
            />
            {item?.item_link ? (
              <Button
                component={FieldLink}
                field={item?.item_link}
                disableTouchRipple
                variant="contained"
                aria-label={item?.item_link_label?.text}
                className={classes.link}
              >
                {item?.item_link_label?.text}
              </Button>
            ) : null}
          </Container>
        </div>
      </div>
    </Paper>
  );
};

ContentGridItemSlice.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles)(ContentGridItemSlice);
