// @flow
import * as React from 'react';
import Container from '@material-ui/core/Container';

export type ContainerWidth = 'contained' | 'full';

export type Props = {
  ...$Exact<React.ElementConfig<typeof Container>>,
  width: ?ContainerWidth,
};

const InnerContainer = ({ width, ...props }: Props): React.Node => (
  <Container
    {...(width &&
      {
        full: {
          maxWidth: false,
          disableGutters: true,
        },
        contained: undefined,
      }[width])}
    {...props}
  />
);

export default InnerContainer;
