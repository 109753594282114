// @flow
import * as React from 'react';
import noop from 'lodash/noop';

import BodySection from '../../components/BodySection';

import type { Props, WithBodySectionSliceHOC, Config } from './types';
import defaultBackgroundColor from './helpers/defaultBackgroundColor';
import defaultPaddingFactor from './helpers/defaultPaddingFactor';

const BodySectionSlice = ({
  node,
  data,
  slices,
  index,
  ...props
}: Props<React.ElementConfig<typeof BodySection>>) => (
  <BodySection {...props} />
);

export default function withBodySectionSlice<Enhanced: {}>(
  config?: Config<Enhanced>,
): WithBodySectionSliceHOC<Enhanced> {
  const {
    backgroundColor = defaultBackgroundColor,
    backgroundGradientDirection = noop,
    paddingFactor = defaultPaddingFactor,
    ContainerProps: ContainerPropsDefault,
    ...bodySectionProps
  } = config || {};

  return Component => {
    const WithBodySectionSlice = (props: Props<Enhanced>) => {
      const { ContainerProps } = props;
      return (
        <Component
          {...bodySectionProps}
          backgroundColor={
            typeof backgroundColor === 'function'
              ? backgroundColor(props)
              : backgroundColor
          }
          backgroundGradientDirection={
            typeof backgroundGradientDirection === 'function'
              ? backgroundGradientDirection(props)
              : backgroundGradientDirection
          }
          paddingFactor={typeof paddingFactor === 'function'
            ? paddingFactor(props)
            : paddingFactor}
          {...props}
          ContainerProps={React.useMemo(
            () => ({
              ...ContainerPropsDefault,
              ...ContainerProps,
            }),
            [ContainerPropsDefault, ContainerProps],
          )}
          component={BodySectionSlice}
        />
      );
    };

    return WithBodySectionSlice;
  };
}
