// @flow
import type { Styles } from './types';

const getTextAlignment = ({ data }) => {
  const size = data.primary?.slice_text_alignment;
  const justify = data.primary?.slice_text_justification;
  switch (size) {
    case 'Right':
      return {
        marginLeft: 'auto',
        textAlign: justify ? 'left' : 'end',
      };
    case 'Center':
      return {
        margin: 'auto',
        textAlign: justify ? 'left' : 'center',
      };
    default:
      return {
        textAlign: 'inherit',
      };
  }
};

const styles: Styles = theme => ({
  root: {},
  wrapper: ({ data }) => ({
    ...getTextAlignment({ data }),
  }),
  title: {
    '& > *': {
      color: theme.palette.text.secondary,
    },
  },
  button: {
    marginTop: theme.spacing(3),
  },
});

export default styles;
