// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import map from 'lodash/map';
import compact from 'lodash/compact';
import classnames from 'classnames';

import NodeImage from '~plugins/prismic/components/NodeImage';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';
import VideoPlayer from '~components/VideoPlayer';
import MediaCaption from '~components/MediaCaption';
import SliceInnerContainer from '~components/SliceInnerContainer';
import ProductsDrawer from '~components/ProductsDrawer';
import getProductListType from '~helpers/getProductListType';

import type { Props } from './types';
import styles from './styles';

const MediaGridSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || 'div';
  const [open, setOpen] = React.useState(false);
  const [selectedProducts, setSelectedProducts] = React.useState(null);

  const handleDrawerOpen = nextProducts => {
    setOpen(true);
    setSelectedProducts(nextProducts);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) => {
          const itemMediaWidth = item?.item_media_width;
          const gridItemProps = {
            '100%': { xs: 12 },
            '50%': { xs: 12, sm: 6 },
          }[itemMediaWidth || '100%'];

          const productList = getProductListType(item?.item_products_list);
          const products =
            productList && productList.data && productList.data.products;

          return item ? (
            <Grid
              key={index}
              item
              {...gridItemProps}
              className={classes.itemsGridItem}
            >
              {item.item_video && item.item_video?.url ? (
                <div className={classes.itemVideoWrapper}>
                  <VideoPlayer
                    controls
                    playsinline
                    width="100%"
                    height="100%"
                    url={item.item_video?.url}
                    videoThumbnail={item.item_image?.url}
                    classes={{
                      root: classes.itemVideo,
                    }}
                  />
                </div>
              ) : (
                <div className={classes.itemImageWrapper}>
                  <NodeImage
                    data={item.item_image}
                    className={classes.itemImage}
                  />
                  {products ? (
                    <IconButton
                      onClick={() => handleDrawerOpen(products)}
                      aria-label="Shopping basket"
                      className={classes.itemAction}
                    >
                      <ShoppingBasketIcon />
                    </IconButton>
                  ) : null}
                </div>
              )}
            </Grid>
          ) : null;
        }),
      ),
    [data.items],
  );
  
  return (
    <Component
      className={classnames(classes.root, className)}
      {...props}
      Container={SliceInnerContainer}
      ContainerProps={{ data }}
    >
      <Grid container spacing={4} className={classes.itemsGridContainer}>
        {renderedItems}
      </Grid>
      <MediaCaption
        text={data?.primary?.slice_caption}
        classes={{ root: classes.mediaCaption }}
      />
      <ProductsDrawer
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
        products={selectedProducts}
      />
    </Component>
  );
};

MediaGridSlice.defaultProps = {
  className: undefined,
};

export const StyledMediaGridSlice = withStyles<*, *, Props>(styles)(
  MediaGridSlice,
);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledMediaGridSlice>,
>()(StyledMediaGridSlice);
