// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import classnames from 'classnames';

import RichText, {
  hasRichTextFieldValue,
} from '~plugins/prismic/components/RichText';
import type { PrismicStructuredTextType } from '~schema';

export type ClassKey = 'root' | 'title' | 'description';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  title?: ?PrismicStructuredTextType,
  description: ?PrismicStructuredTextType,
  backgroundColor?: ?string,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
  },
  title: {
    '& > *': {
      marginBottom: theme.spacing(2),
      color: ({ backgroundColor }) =>
        backgroundColor === null ? theme.palette.text.secondary : 'inherit',
    },
  },
  description: {
    '& *:not(:last-child)': {
      paddingBottom: theme.spacing(2),
    },
    '& a, & strong': {
      color: theme.palette.secondary.main,
    },
  },
});

const TitleAndDescription = ({
  title,
  description,
  backgroundColor,
  className,
  classes,
  ...props
}: Props): React.Node => {
  return (
    <div className={classnames(classes.root, className)} {...props}>
      {hasRichTextFieldValue(title) ? <RichText {...title} className={classes.title} /> : null}
      {description ? (
        <RichText {...description} className={classes.description} />
      ) : null}
    </div>
  );
};

TitleAndDescription.defaultProps = {
  className: undefined,
  title: undefined,
  backgroundColor: undefined,
};

export default withStyles<*, *, Props>(styles)(TitleAndDescription);
