// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import classnames from 'classnames';

import RichTextTypography, {
  hasRichTextFieldValue,
} from '~plugins/prismic/components/RichTextTypography';
import getTitleVariant from '~plugins/prismic/components/RichTitle/helpers/getTextVariant';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';
import type { Props } from './types';
import styles from './styles';

const HeaderSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || 'div';
  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <Container maxWidth="md" className={classes.wrapper}>
        <RichTextTypography
          component="h1"
          variant={getTitleVariant(data?.primary?.slice_title?.html)}
          {...data?.primary?.slice_title}
          className={classes.title}
        />
        {hasRichTextFieldValue(data?.primary?.slice_description) ? (
          <RichTextTypography
            component="h2"
            variant={getTitleVariant(data?.primary?.slice_description?.html)}
            {...data?.primary?.slice_description}
            className={classes.description}
          />
        ) : null}
      </Container>
    </Component>
  );
};

HeaderSlice.defaultProps = {
  className: undefined,
};

export const StyledHeaderSlice = withStyles<*, *, Props>(styles)(HeaderSlice);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledHeaderSlice>,
>({ Container: null })(StyledHeaderSlice);
