// @flow
import * as sliceBackgroundColor from '../config/sliceBackgroundColor';
import type { BackgroundColor } from '../types';

const backgroundColorDict = ({
  [sliceBackgroundColor.Primary]: 'primary',
  [sliceBackgroundColor.PrimaryLight]: 'primaryLight',
  [sliceBackgroundColor.PrimaryDark]: 'primaryDark',
  [sliceBackgroundColor.Secondary]: 'secondary',
  [sliceBackgroundColor.SecondaryLight]: 'secondaryLight',
  [sliceBackgroundColor.SecondaryDark]: 'secondaryDark',
  [sliceBackgroundColor.Tertiary]: 'tertiary',
  [sliceBackgroundColor.TertiaryLight]: 'tertiaryLight',
  [sliceBackgroundColor.TertiaryDark]: 'tertiaryDark',
  [sliceBackgroundColor.Quaternary]: 'quaternary',
  [sliceBackgroundColor.QuaternaryLight]: 'quaternaryLight',
  [sliceBackgroundColor.QuaternaryDark]: 'quaternaryDark',
  [sliceBackgroundColor.Grey]: 'grey',
  [sliceBackgroundColor.White]: 'white',
  [sliceBackgroundColor.Black]: 'black',
}: $ObjMap<typeof sliceBackgroundColor, <V>() => BackgroundColor>);

const sanitizeBackgroundColor = (value: mixed): void | BackgroundColor =>
  (!!value && typeof value === 'string' && backgroundColorDict[value]) ||
  undefined;

export default sanitizeBackgroundColor;
