// @flow
import type { Variant } from '../types';

import * as variants from '../config/variants';

const getTextVariant = (
  html: ?string,
  defaultVariant?: Variant = 'h1',
): Variant => {
  const match = html ? html.match(/^<(h[1-6])/) : null;
  const variant = match && match[1];
  // $FlowFixMe --> Reason: 
  return variant && variants[variant] ? variant : defaultVariant;
};

export default getTextVariant;