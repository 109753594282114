// @flow
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import type { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import type { CSSStyleRuleBase } from '@material-ui/core/styles/withStyles';
import merge from 'lodash/merge';
import mapValues from 'lodash/mapValues';
import castArray from 'lodash/castArray';

import type { GradientDirection } from '~plugins/material-ui/modules/gradients/types';
import getGradientColorStops from '~plugins/material-ui/modules/gradients/helpers/getGradientColorStops';
import getGradientCSSGradient from '~plugins/material-ui/modules/gradients/helpers/getGradientCSSGradient';

import type {
  ThemeOverrides,
  ThemeProps,
  BackgroundColor,
  PaddingFactorInput,
  PaddingFactor,
  PaddingFactorArray,
  PaddingFactorBreakpoints,
} from '../types';

export const getThemeProps = (theme: Theme): ThemeProps =>
  merge(
    ({
      paddingFactor: [6, 0],
      paddingAlterFactor: {
        xs: 1,
      },
    }: ThemeProps),
    // $FlowFixMe --> Reason: mui theme props
    theme.props?.BodySection,
  );

export const getThemeOverrides = (theme: Theme): ThemeOverrides =>
  merge(
    ({
      backgroundColors: {
        primary: theme.palette.primary.main,
        primaryLight: theme.palette.primary.light,
        primaryDark: theme.palette.primary.dark,
        secondary: theme.palette.secondary.main,
        secondaryLight: theme.palette.secondary.light,
        secondaryDark: theme.palette.secondary.dark,
        tertiary: theme.palette.tertiary.main,
        tertiaryLight: theme.palette.tertiary.light,
        tertiaryDark: theme.palette.tertiary.dark,
        quaternary: theme.palette.quaternary.main,
        quaternaryLight: theme.palette.quaternary.light,
        quaternaryDark: theme.palette.quaternary.dark,
        grey: theme.palette.grey[300],
        white: theme.palette.common.white,
        black: theme.palette.common.black,
      },
      contrastTextColors: {
        primary: theme.palette.primary.contrastText,
        primaryLight: theme.palette.primary.contrastText,
        primaryDark: theme.palette.primary.contrastText,
        secondary: theme.palette.secondary.contrastText,
        secondaryLight: theme.palette.secondary.contrastText,
        secondaryDark: theme.palette.secondary.contrastText,
        tertiary: theme.palette.tertiary.contrastText,
        tertiaryLight: theme.palette.tertiary.contrastText,
        tertiaryDark: theme.palette.tertiary.contrastText,
        quaternary: theme.palette.quaternary.contrastText,
        quaternaryLight: theme.palette.quaternary.contrastText,
        quaternaryDark: theme.palette.quaternary.contrastText,
        grey: theme.palette.primary.contrastText,
        white: theme.palette.primary.contrastText,
        black: theme.palette.common.white,
      },
      accentTextColors: {
        primary: theme.palette.secondary.dark,
        primaryLight: theme.palette.secondary.dark,
        primaryDark: theme.palette.secondary.dark,
        secondary: theme.palette.primary.dark,
        secondaryLight: theme.palette.primary.dark,
        secondaryDark: theme.palette.primary.dark,
        tertiary: theme.palette.primary.dark,
        tertiaryLight: theme.palette.primary.dark,
        tertiaryDark: theme.palette.primary.dark,
        quaternary: theme.palette.secondary.dark,
        quaternaryLight: theme.palette.secondary.dark,
        quaternaryDark: theme.palette.secondary.dark,
        grey: theme.palette.common.black,
        white: theme.palette.common.black,
        black: theme.palette.common.white,
      },
    }: ThemeOverrides),
    // $FlowFixMe --> Reason: mui theme overrides
    theme.overrides?.BodySection,
  );

export const castPaddingFactorArray = (
  paddingFactor: ?PaddingFactor,
): ?PaddingFactorArray =>
  typeof paddingFactor === 'number' ? [paddingFactor] : paddingFactor;

export const getPaddingFactorElement = (
  paddingFactor?: PaddingFactor,
  ...indexes: Array<number>
): void | number => {
  const paddingFactorArray = castPaddingFactorArray(paddingFactor) || [];
  // eslint-disable-next-line no-restricted-syntax
  for (const index of indexes) {
    if (typeof paddingFactorArray[index] === 'number') {
      return paddingFactorArray[index];
    }
  }
  return undefined;
};

export const getPaddingFactor = (
  theme: Theme,
  paddingFactor?: ?PaddingFactorInput,
): ?PaddingFactor => {
  const theme$paddingFactor = getThemeProps(theme).paddingFactor;
  const theme$paddingFactorArray = castPaddingFactorArray(theme$paddingFactor);

  // eslint-disable-next-line no-nested-ternary
  return typeof paddingFactor === 'undefined' || paddingFactor === null
    ? theme$paddingFactor
    : Array.isArray(paddingFactor)
    ? paddingFactor.map((factorElement, index) =>
        typeof factorElement === 'number'
          ? factorElement
          : (theme$paddingFactorArray &&
              {
                '0': getPaddingFactorElement(theme$paddingFactorArray, 0),
                '1': getPaddingFactorElement(theme$paddingFactorArray, 1),
                '2': getPaddingFactorElement(theme$paddingFactorArray, 2, 0),
                '3': getPaddingFactorElement(theme$paddingFactorArray, 3, 1),
              }[index.toString()]) ||
            0,
      )
    : paddingFactor;
};

export const getPaddingAlterFactor = (
  theme: Theme,
): PaddingFactorBreakpoints<PaddingFactorArray> => {
  const rawAlterFactor = getThemeProps(theme).paddingAlterFactor || {};
  return theme.breakpoints.keys.reduce(
    (acc, key, index, keys) => ({
      ...acc,
      [key]: castArray(
        Number.isFinite(rawAlterFactor[key])
          ? rawAlterFactor[key]
          : (index === 0 && 1) || acc[keys[index - 1]],
      ),
    }),
    {},
  );
};

export const getPaddingFactorBreakpoints = (
  theme: Theme,
  paddingFactor?: ?PaddingFactorInput,
): PaddingFactorBreakpoints<PaddingFactorArray> => {
  const factor: PaddingFactorArray =
    castPaddingFactorArray(getPaddingFactor(theme, paddingFactor)) || [];
  return mapValues(
    getPaddingAlterFactor(theme),
    (rawAlterFactor: PaddingFactorArray) => {
      const alterFactor = castArray(rawAlterFactor);
      return factor.map(
        (factorValue, index) =>
          factorValue * alterFactor[index % alterFactor.length],
      );
    },
  );
};

type ReducePaddingFactorStylesIteratee = (
  factor: PaddingFactorArray,
  key: Breakpoint,
  PaddingFactorBreakpoints<PaddingFactorArray>,
) => { [string]: CSSStyleRuleBase };

const reducePaddingFactorStylesDefaultIteratee: (
  theme: Theme,
) => ReducePaddingFactorStylesIteratee = theme => factor => ({
  padding: theme.spacing(...factor),
});

export const reducePaddingFactorStyles = (
  theme: Theme,
  paddingFactor?: ?PaddingFactorInput,
  iteratee?: ReducePaddingFactorStylesIteratee = reducePaddingFactorStylesDefaultIteratee(
    theme,
  ),
): { [string]: CSSStyleRuleBase } => {
  const factors = getPaddingFactorBreakpoints(theme, paddingFactor);
  // $FlowFixMe --> Reason: Object.entries is not typed
  return Object.entries(factors).reduce(
    (acc, [key, factor]: [Breakpoint, PaddingFactorArray]) => ({
      ...acc,
      [theme.breakpoints.up(key)]: iteratee(factor, key, factors),
    }),
    {},
  );
};

export const getBackgroundColor = (
  theme: Theme,
  backgroundColor?: ?BackgroundColor,
) => backgroundColor || getThemeProps(theme).backgroundColor;

export const getBackgroundGradientDirection = (
  theme: Theme,
  backgroundGradientDirection?: ?GradientDirection,
) =>
  backgroundGradientDirection ||
  getThemeProps(theme).backgroundGradientDirection;

export const getBackgroundColorCSSValue = (
  theme: Theme,
  backgroundColor: ?BackgroundColor,
) => {
  const themeOverrides = getThemeOverrides(theme);
  return (
    (backgroundColor &&
      themeOverrides.backgroundColors &&
      themeOverrides.backgroundColors[backgroundColor]) ||
    undefined
  );
};

export const getBackgroundGradientCSSValue = (
  theme: Theme,
  backgroundColor: ?BackgroundColor,
  direction?: ?GradientDirection,
) => {
  const themeOverrides = getThemeOverrides(theme);

  const colorStops = getGradientColorStops(
    {
      colorStops: {
        ...themeOverrides.backgroundColors,
        ...themeOverrides.backgroundGradientColorStops,
      },
      coefficient: themeOverrides.backgroundGradientDefaultColorCoefficient,
    },
    backgroundColor,
  );

  return getGradientCSSGradient({ colorStops, direction }) || undefined;
};

export const getContrastTextColorCSSValue = (
  theme: Theme,
  backgroundColor: ?BackgroundColor,
) => {
  const themeOverrides = getThemeOverrides(theme);
  return (
    (backgroundColor &&
      themeOverrides.contrastTextColors &&
      themeOverrides.contrastTextColors[backgroundColor]) ||
    undefined
  );
};

export const getAccentTextColorCSSValue = (
  theme: Theme,
  backgroundColor: ?BackgroundColor,
) => {
  const themeOverrides = getThemeOverrides(theme);
  return (
    (backgroundColor &&
      themeOverrides.accentTextColors &&
      themeOverrides.accentTextColors[backgroundColor]) ||
    undefined
  );
};
