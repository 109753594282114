// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: ({ data }) => ({
    ...(data?.primary?.slice_width === 'Fullwidth'
      ? {
          padding: 0,
        }
      : undefined),
  }),
  mediaCaption: {
    marginTop: theme.spacing(2),
  },
  itemsGridContainer: {},
  itemsGridItem: {},
  itemVideoWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
    height: '100%',
  },
  itemVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  itemImageWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  itemImage: {
    width: '100%',
    height: '100%',
  },
  itemAction: {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
    },
  },
});

export default styles;
