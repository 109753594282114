// @flow
export const Primary: 'Primary' = 'Primary';
export const PrimaryLight: 'PrimaryLight' = 'PrimaryLight';
export const PrimaryDark: 'PrimaryDark' = 'PrimaryDark';
export const Secondary: 'Secondary' = 'Secondary';
export const SecondaryLight: 'SecondaryLight' = 'SecondaryLight';
export const SecondaryDark: 'SecondaryDark' = 'SecondaryDark';
export const Tertiary: 'Tertiary' = 'Tertiary';
export const TertiaryLight: 'TertiaryLight' = 'TertiaryLight';
export const TertiaryDark: 'TertiaryDark' = 'TertiaryDark';
export const Quaternary: 'Quaternary' = 'Quaternary';
export const QuaternaryLight: 'QuaternaryLight' = 'QuaternaryLight';
export const QuaternaryDark: 'QuaternaryDark' = 'QuaternaryDark';
export const Grey: 'Grey' = 'Grey';
export const White: 'White' = 'White';
export const Black: 'Black' = 'Black';
