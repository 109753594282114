// @flow
import * as React from 'react';
import { useIntl } from 'react-intl';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import classnames from 'classnames';
import map from 'lodash/map';

import Link from '~plugins/i18n/components/Link';
import type { PrismicProductListProductsGroupType } from '~schema';

import messages from '../messages';

export type ClassKey =
  | 'root'
  | 'wrapper'
  | 'titleWrapper'
  | 'title'
  | 'closeButton'
  | 'list'
  | 'listItem'
  | 'listItemDetails'
  | 'listItemTitle'
  | 'listItemAction'
  | 'listItemMedia';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  open: boolean,
  onClose: () => void,
  products?: ?Array<?PrismicProductListProductsGroupType>,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {},
  wrapper: {},
  titleWrapper: {
    marginTop: theme.spacing(1.5),
  },
  title: {
    width: '100%',
    display: 'flex',
    fontFamily: theme.typography.h4.fontFamily,
    paddingBottom: theme.spacing(1),
  },
  closeButton: {
    float: 'right',
    marginBottom: theme.spacing(3),
  },
  list: {
    marginTop: theme.spacing(3),
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    paddingRight: 0,
  },
  listItemTitle: {
    fontFamily: theme.typography.h4.fontFamily,
    color: theme.palette.secondary.main,
  },
  listItemAction: {
    paddingTop: theme.spacing(1),
  },
  listItemMedia: {
    width: 150,
    height: 150,
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    objectFit: 'contain',
    flex: 'none',
  },
});

const ProductsDrawer = ({
  open,
  onClose,
  products,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const intl = useIntl();

  const splitString = ({
    text,
    append,
  }: {
    text: string,
    append: string,
  }): string => {
    return text.length < 40 ? text : `${text.slice(0, 40)}${append}`;
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      className={classnames(classes.root, className)}
      {...props}
    >
      <Container maxWidth="xs" className={classes.wrapper}>
        <div className={classes.titleWrapper}>
          <Button
            startIcon={<CloseIcon />}
            onClick={onClose}
            aria-label={intl.formatMessage(messages.closeIcon)}
            className={classes.closeButton}
          >
            {intl.formatMessage(messages.closeIcon)}
          </Button>
          <Typography variant="overline" className={classes.title}>
            {intl.formatMessage(messages.productsDrawerTitle)}
          </Typography>
          <Divider />
        </div>
        <div className={classes.list}>
          {map(products, (product, index: number) => {
            const productTitle = product?.product?.title;
            const truncatedProductTitle =
              productTitle &&
              splitString({ text: productTitle, append: '...' });
            const productPrice = product?.product?.variants[0]?.price;
            const productPriceWithCurrency = productPrice && `${productPrice}€`;
            const productHandle = product?.product?.handle;
            const productPage =
              productHandle &&
              `https://www.velodrom.cc/products/${productHandle}`;

            return (
              <ListItem key={index} className={classes.listItem}>
                <img
                  src={product?.product?.image?.src}
                  alt={product?.product?.title}
                  className={classes.listItemMedia}
                />
                <div className={classes.listItemDetails}>
                  <Typography
                    variant="caption"
                    className={classes.listItemTitle}
                  >
                    {truncatedProductTitle}
                  </Typography>
                  <Typography variant="caption">
                    {productPriceWithCurrency}
                  </Typography>
                  <div className={classes.listItemAction}>
                    <Button
                      component={Link}
                      to={productPage}
                      target="_blank"
                      rel="noopener noreferrer"
                      localized
                      variant="contained"
                      color="secondary"
                      aria-label={intl.formatMessage(messages.buyButton)}
                    >
                      {intl.formatMessage(messages.buyButton)}
                    </Button>
                  </div>
                </div>
              </ListItem>
            );
          })}
        </div>
      </Container>
    </Drawer>
  );
};

ProductsDrawer.defaultProps = {
  className: undefined,
  products: undefined,
};

export default withStyles<*, *, Props>(styles)(ProductsDrawer);
