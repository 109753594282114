// @flow
import * as React from 'react';

import InnerContainer from '~components/InnerContainer';

import sanitizeContainerWidth from './helpers/sanitizeContainerWidth';

import type { Props } from './types';

const SliceInnerContainer = ({
  data,
  children,
  className,
  ...props
}: Props): React.Node => {
  return (
    <InnerContainer
      width={sanitizeContainerWidth(data?.primary?.slice_width)}
      {...props}
    >
      {children}
    </InnerContainer>
  );
};

SliceInnerContainer.defaultProps = {
  className: undefined,
};

export default SliceInnerContainer;
