// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Container from '@material-ui/core/Container';
import classnames from 'classnames';

import RichTextTypography from '~plugins/prismic/components/RichTextTypography';
import hasRichTextFieldValue from '~plugins/prismic/helpers/hasRichTextFieldValue';
import type { PrismicStructuredTextType } from '~schema';

export type ClassKey = 'root' | 'text';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  text: ?PrismicStructuredTextType,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = unusedTheme => ({
  root: {},
  text: {
    textAlign: 'center',
  },
});

const MediaCaption = ({
  text,
  className,
  classes,
  ...props
}: Props): React.Node => {
  return hasRichTextFieldValue(text) ? (
    <Container
      maxWidth="sm"
      className={classnames(classes.root, className)}
      {...props}
    >
      <RichTextTypography
        variant="caption"
        {...text}
        className={classes.text}
      />
    </Container>
  ) : null;
};

MediaCaption.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles)(MediaCaption);
