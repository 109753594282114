// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';

import FieldLink from '~plugins/prismic/components/FieldLink';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';
import TitleAndDescription from '~components/TitleAndDescription';
import type { Props } from './types';
import styles from './styles';

const PlainTextSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;
  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <TitleAndDescription
        title={data?.primary?.slice_title}
        description={data?.primary?.slice_text}
        classes={{ root: classes.wrapper, title: classes.title }}
      />
      {data?.primary?.slice_link ? (
        <Button
          component={FieldLink}
          field={data.primary?.slice_link}
          disableTouchRipple
          variant="contained"
          color="secondary"
          aria-label={data?.primary?.slice_link_label?.text}
          className={classes.button}
        >
          {data?.primary?.slice_link_label?.text}
        </Button>
      ) : null}
    </Component>
  );
};

PlainTextSlice.defaultProps = {
  className: undefined,
};

export const StyledPlainTextSlice = withStyles<*, *, Props>(styles)(
  PlainTextSlice,
);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledPlainTextSlice>,
>()(StyledPlainTextSlice);
