// @flow
/* eslint-disable no-use-before-define */
import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import take from 'lodash/take';
import flow from 'lodash/fp/flow';
import replace from 'lodash/fp/replace';

import RichText from '../RichText';

export type Props = {
  ...$Exact<React.ElementConfig<typeof Typography>>,
  ...$Exact<$Diff<React.ElementConfig<typeof RichText>, { Component: any }>>,
  className?: string,
  smallestTitleReplacement?: string,
};

/*
This regular expression will remove all MuiTypography classes already set by
the `htmlSerializer`. This is done because this html will be rendered inside
a new `Typography` component, and the children styles will prevail on the
parent ones.
*/
const regExp = /MuiTypography(-|[a-z]|[1-9])+/gi;

/**
 * Takes a rich text field and renders it inside a Typography. It will strip all
 * typography classes inside the provided html string.
 *
 * Using {@link RichText} by default is prefered, but since it is kind of rigid
 * (because it is limited to the tags provided by Prismic), in some cases it may
 * be useful to use `RichTextTypography`, since it will let to override the
 * already serialized typography classes.
 *
 * Please note that weird styles can appear, as only the provided variant will be
 * set, but the html provided may contain any of the available tags in Prismic.
 * This can lead to weird combinations (ex: variant set to `caption` rendering
 * `h1`).
 */
const RichTextTypography = ({
  html,
  component,
  variant,
  smallestTitleReplacement,
  ...props
}: Props): React.Node => {
  return (
    <RichText
      // FIXME: spreading component prop to avoid flow error with Typography
      {...{ component: component || 'div' }}
      variant={variant}
      {...props}
      html={React.useMemo(
        () =>
          html &&
          flow(
            replace(regExp, ''),
            currySanitizeTitles({
              component,
              variant,
              smallestTitleReplacement,
            }),
            curryRemoveRootTitle({
              component,
              variant,
            }),
          )(html),
        [html, component, variant, smallestTitleReplacement],
      )}
      Component={Typography}
    />
  );
};

RichTextTypography.defaultProps = {
  className: undefined,
  smallestTitleReplacement: undefined,
};

export default RichTextTypography;

const titles = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

function currySanitizeTitles({
  variant,
  component = variant,
  smallestTitleReplacement = 'span',
}) {
  const titleIndex =
    typeof component === 'string' ? titles.indexOf(component) : -1;
  const titlesToRemove = take(titles, titleIndex + 1);
  const replacement = titles[titleIndex + 1] || smallestTitleReplacement;

  return html => {
    return titlesToRemove.reduce(
      (acc, title) =>
        acc
          .replace(new RegExp(`<${title}`), `<${replacement}`)
          .replace(new RegExp(`</${title}`), `</${replacement}`),
      html,
    );
  };
}

function curryRemoveRootTitle({ variant, component = variant }) {
  const titleIndex =
    typeof component === 'string' ? titles.indexOf(component) : -1;

  const startTagRegExp = new RegExp(`^(<(${titles.join('|')})([^>]+)>)`);
  const endTagRegExp = new RegExp(`(</\\s?(${titles.join('|')})([^>]*)>)$`);
  return html =>
    titleIndex >= 0
      ? html.replace(startTagRegExp, '').replace(endTagRegExp, '')
      : html;
}
