// @flow
import type { Styles } from './types';
import getBreakPointFactor from '~plugins/material-ui/components/ResPadding/helpers/getPaddingFactor';

const styles: Styles = theme => ({
  root: {},
  titleContainer: {
    ...theme.breakpoints.keys.reduce(
      (acc, breakpoint) => ({
        ...acc,
        [theme.breakpoints.up(breakpoint)]: {
          paddingBottom: theme.spacing(getBreakPointFactor(breakpoint) * 2),
        },
      }),
      {},
    ),
  },
  itemsGridContainer: {},
  itemsGridItem: {},
  itemWrapper: {
    [theme.breakpoints.up('md')]: {
      padding: ({ data }) =>
        data?.primary?.items_per_row === '1'
          ? `${theme.spacing(3)}px ${theme.spacing(7)}px`
          : undefined,
    },
  },
  itemContent: {
    [theme.breakpoints.up('md')]: {
      textAlign: ({ data }) =>
        data?.primary?.items_per_row === '1' ? 'left' : 'center',
      '& p': {
        fontSize: ({ data }) =>
          data?.primary?.items_per_row === '1'
            ? theme.typography.h6.fontSize
            : 'initial',
      },
    },
  },
  itemHiddenContent: {
    margin: ({ data }) =>
      (data?.primary?.items_per_row || '0') > '1' ? 'auto' : 'inherit',
  },
});

export default styles;
