// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import map from 'lodash/map';
import compact from 'lodash/compact';
import classnames from 'classnames';

import TitleAndDescription from '~components/TitleAndDescription';
import SliceInnerContainer from '~components/SliceInnerContainer';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';
import hasRichTextFieldValue from '~plugins/prismic/helpers/hasRichTextFieldValue';

import type { Props } from './types';
import styles from './styles';
import ContentGridItemSlice from './ContentGridItemSlice';

const ContentGridSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || 'div';
  const lineItems = data.primary?.items_per_row;
  const gridItemProps = {
    '1': { xs: 12 },
    '2': { xs: 12, sm: 6 },
    '3': { xs: 12, sm: 6, md: 4 },
    '4': { xs: 12, sm: 6, md: 3 },
  }[lineItems || '1'];

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) =>
          item ? (
            <Grid
              key={index}
              item
              {...gridItemProps}
              className={classes.itemsGridItem}
            >
              <ContentGridItemSlice
                item={item}
                classes={{
                  root: classes.itemWrapper,
                  content: classes.itemContent,
                  hiddenContent: classes.itemHiddenContent,
                }}
              />
            </Grid>
          ) : null,
        ),
      ),
    [data.items],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      {hasRichTextFieldValue(
        data?.primary?.slice_title || data?.primary?.slice_description,
      ) ? (
        <Container className={classes.titleContainer}>
          <TitleAndDescription
            title={data?.primary?.slice_title}
            description={data?.primary?.slice_description}
            backgroundColor={data?.primary?.slice_background_color}
          />
        </Container>
      ) : null}
      <SliceInnerContainer data={data}>
        <Grid container spacing={1} className={classes.itemsGridContainer}>
          {renderedItems}
        </Grid>
      </SliceInnerContainer>
    </Component>
  );
};

ContentGridSlice.defaultProps = {
  className: undefined,
};

export const StyledContentGridSlice = withStyles<*, *, Props>(styles)(
  ContentGridSlice,
);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledContentGridSlice>,
>({ Container: null })(StyledContentGridSlice);
