// @flow
import type { Props } from '../types';
import sanitizePaddingFactor from './sanitizePaddingFactor';

const defaultPaddingFactor = <E: {}>({ data }: Props<E>) =>
  sanitizePaddingFactor({
    top: data.primary?.slice_padding_top,
    right: data.primary?.slice_padding_right,
    bottom: data.primary?.slice_padding_bottom,
    left: data.primary?.slice_padding_left,
  });

export default defaultPaddingFactor;
