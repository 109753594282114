// @flow
import type { PrismicLinkType, PrismicProductList } from '~schema';

export default function getProductListType(
  link: ?PrismicLinkType,
): PrismicProductList | void {
  const document = link && link.document;
  return document &&
    (document.__typename === 'PrismicProductList' || document.type === 'product_lists')
    ? // $FlowFixMe --> Reason: not infering type from `document.type`
      document
    : undefined;
}
