// @flow

import type { ContainerWidth } from '~components/InnerContainer';

export default function sanitizeContainerWidth(
  width: mixed,
): ContainerWidth | void {
  return typeof width === 'string'
    ? {
        Contained: 'contained',
        Fullwidth: 'full',
      }[width]
    : undefined;
}
