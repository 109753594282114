// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {
    '& .react-player__shadow': {
      boxShadow:
        'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px',
      background: `${theme.palette.primary.light} !important`,
      transition: theme.transitions.create(['background'], {
        duration: theme.transitions.duration.shortest,
      }),
      [theme.breakpoints.up('md')]: {
        width: '75px !important',
        height: '75px !important',
      },
      '&:hover': {
        background: `${theme.palette.primary.dark} !important`,
      },
    },
    '& .react-player__play-icon': {
      borderColor: `transparent transparent transparent ${theme.palette.common.white} !important`,
    },
  },
});

export default styles;
