// @flow

module.exports['0'] = (0: 0);
module.exports['1'] = (1: 1);
module.exports['2'] = (2: 2);
module.exports['3'] = (3: 3);
module.exports['4'] = (4: 4);
module.exports['5'] = (5: 5);
module.exports['6'] = (6: 6);
module.exports['7'] = (7: 7);
module.exports['8'] = (8: 8);
module.exports['9'] = (9: 9);
module.exports['10'] = (10: 10);
module.exports['11'] = (11: 11);
module.exports['12'] = (12: 12);
