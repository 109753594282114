// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {
    textAlign: 'center',
    color: theme.palette.common.white,
    backgroundImage: ({ data }) =>
      // $FlowFixMe --> Reason: property `primary` is missing in  `Node`
      `url(${data?.primary?.slice_image?.url})`,
    backgroundBlendMode: 'multiply',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundColor: `rgba(0, 0, 0, 0.2)`,
    height: '100%',
    minHeight: ({ data }) =>
      data?.primary?.slice_height === 'Tall' ? '75vh' : '40vh',
  },
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    textShadow: '4px 4px 15px rgba(0,0,0,.3)',
  },
  description: {
    paddingTop: theme.spacing(2),
    textShadow: '4px 4px 15px rgba(0,0,0,.3)',
  },
});

export default styles;
